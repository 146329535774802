@media only screen and (min-width: 1120px) {
	header {
		left: 50px;
	}

	main {
		margin: 20px 20px 0 400px;
	}

	footer {
		margin: 0 0 0 400px;
	}
}

@media only screen and (min-width: 960px) and (max-width: 1119px) {
	header {
		left: calc(25vw - 240px);
	}
	main {
		margin: 20px 20px 0 auto;
	}
	footer {
		margin: 0 20px 0 auto;
	}
}

@media only screen and (min-width: 960px) {
	header {
		position: fixed;
		top: 50px;
	}
	header nav {
		margin-top: -40px;
		overflow-y: scroll;
		max-height: calc(100vh - 264px)
	}
	#icon {
		width: 214px;
		height: 214px;
	}
	#icon {
		border-radius: 107px;
		background-position: center;
	}
	header .theme {
		top: 142px;
		right: 0;
	}
	header nav {
		width: 220px;
		border-width: 3px;
		border-radius: 6px;
	}
}

@media only screen and (min-width: 960px) and (pointer: coarse) {
	header .theme {
		top: 126px;
		right: -16px;
	}
}

@media only screen and (min-width: 1008px) and (max-width: 1055px) and (pointer: coarse) {
	header .theme:hover, .hiddenControl[name="theme"]:focus ~ #bodyContainer header .theme {
		padding: 4px calc(25vw - 184px) 4px 4px;
	}
}

@media only screen and (min-width: 960px) and (max-width: 1007px) and (pointer: coarse) {
	header .theme:hover, .hiddenControl[name="theme"]:focus ~ #bodyContainer header .theme {
		padding: 4px 4px 4px 4px;
	}
}

@media only screen and (min-width: 500px) and (max-width: 959px) {
	header nav {
		padding-top: 20px;
	}

	header .theme {
		top: 5px;
		right: 5px;
		max-width: 200px;
	}
}

@media only screen and (min-width: 700px) and (max-width: 959px) {
	header {
		margin: 0 0 10px 0;
	}
	main {
		margin: 0 auto 0 auto;
	}
	footer {
		margin: 0 auto 0 auto;
	}
}

@media only screen and (max-width: 959px) {
	header {
		display: flex;
		width: 100%;
		border-color: black;
		border-bottom-width: 2px;
	}
	header nav ul {
		flex-direction: row;
		flex-wrap: wrap;
	}
	header nav li {
		flex: 1 0 140px;
	}
	header nav {
		flex: 1 1;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
	}
}

@media only screen and (min-width: 700px) {
	main {
		min-width: 700px;
		border-width: 3px;
		border-radius: 6px;
	}
	footer {
		width: 700px;
	}
}

@media only screen and (max-width: 699px) {
	main {
		border-bottom-width: 3px;
		min-width: 100%;
	}
	footer {
		min-width: 100%;
	}
}

@media only screen and (min-width: 700px) and (max-width: 959px) {
	#icon {
		width: 210px;
		border: 0;
	}
}

@media only screen and (min-width: 500px) and (max-width: 699px) {
	#icon {
		width: 30%;
		border: 0;
	}
}

@media only screen and (min-width: 500px) {
	header {
		flex-direction: row;
	}
	dialog .buttons {
		flex-direction: row;
	}
}

@media only screen and (max-width: 499px) {
	header {
		flex-direction: column;
	}
	dialog .buttons {
		flex-direction: column;
	}
	#icon {
		height: 40vw;
		border-width: 0;
	}
	header .theme {
		top: calc(40vw - 37px);
		right: 5px;
	}
	header nav {
		border-top-width: 1px;
	}
	.tagNav .tag {
		flex-direction: column;
		align-items: stretch;
		gap: 0.2em;
	}
	.tagNav .tag .tagName {
		max-width: none;
	}
}

@media only screen and (max-width: 499px) and (pointer: coarse) {
	header .theme {
		top: calc(40vw - 69px);
	}
}