@media only print {
    .authorsnote {
        border-color: black;
		background-color: lightgray;
    }

    .nsfwwarning, .draftwarning {
        color: black;
        border-color: black;
    }

    footer a {
		color: inherit;
    }

    .nsfwwarning {
        background-color: darkgray;
    }

    .draftwarning {
        background-color: lightgray;
    }
}

@media only screen {
	header .theme, #icon, header nav, main, dialog {
		box-shadow: black 3px 3px 10px;
	}

    dialog {
        color: inherit;
    }

    dialog::backdrop {
        background-color: #202020c0;
    }

    .nsfwwarning, .draftwarning {
        color: white;
        border-color: #330000;
    }

    .nsfwwarning {
        background-color: darkred;
    }

    .draftwarning.inactive {
        background-color: #000090;
    }

    .draftwarning.active {
        background-color: #0070C0;
    }

    #bodyContainer, #forceLight:checked ~ #bodyContainer {
        color: black;
    }

    #forceDark:checked ~ #bodyContainer {
        color: white;
    }

    footer, #forceLight:checked ~ #bodyContainer footer, #forceDark:checked ~ #bodyContainer footer {
		color: white;
		text-shadow: black 1px 1px 1px, black -1px 1px 1px, black 1px -1px 1px, black -1px -1px 1px;
    }

    footer a, #forceLight:checked ~ #bodyContainer footer a, #forceDark:checked ~ #bodyContainer footer a {
        color: #A1F6FF;
    }

    footer a:hover, #forceLight:checked ~ #bodyContainer footer a:hover, #forceDark:checked ~ #bodyContainer footer a:hover,
    footer a:focus, #forceLight:checked ~ #bodyContainer footer a:focus, #forceDark:checked ~ #bodyContainer footer a:focus {
		color: dodgerblue;
        outline: none;
	}

	footer a:active, #forceLight:checked ~ #bodyContainer footer a:active, #forceDark:checked ~ #bodyContainer footer a:active {
		color: blue;
	}

    a, button, #forceLight:checked ~ #bodyContainer a, #forceLight:checked ~ #bodyContainer button {
		color: hsl(216, 83%, 40%);
	}

	a:hover, button:hover:not(:disabled), #forceLight:checked ~ #bodyContainer a:hover, #forceLight:checked ~ #bodyContainer button:hover:not(:disabled),
    a:focus, button:focus:not(:disabled), #forceLight:checked ~ #bodyContainer a:focus, #forceLight:checked ~ #bodyContainer button:focus:not(:disabled) {
		color: hsl(216, 90%, 50%);
        outline: none;
	}

	a:active, button:active:not(:disabled), #forceLight:checked ~ #bodyContainer a:active, #forceLight:checked ~ #bodyContainer button:active:not(:disabled) {
		color: hsl(216, 33%, 30%);
	}

    #forceDark:checked ~ #bodyContainer a, #forceDark:checked ~ #bodyContainer button {
		color: #A1F6FF;
	}

	#forceDark:checked ~ #bodyContainer a:hover, #forceDark:checked ~ #bodyContainer button:hover:not(:disabled),
    #forceDark:checked ~ #bodyContainer a:focus, #forceDark:checked ~ #bodyContainer button:focus:not(:disabled) {
		color: dodgerblue;
	}

	#forceDark:checked ~ #bodyContainer a:active, #forceDark:checked ~ #bodyContainer button:active:not(:disabled) {
		color: blue;
	}

	#icon, #forceLight:checked ~ #bodyContainer #icon {
		filter: none;
	}

	#icon:hover, #forceLight:checked ~ #bodyContainer #icon:hover, #icon:focus, #forceLight:checked ~ #bodyContainer #icon:focus {
		filter: brightness(120%) saturate(120%);
        outline: none;
	}

	#icon:active, #forceLight:checked ~ #bodyContainer #icon:active {
		filter: brightness(80%) saturate(80%);
	}

    #forceDark:checked ~ #bodyContainer #icon {
        filter: brightness(50%) saturate(120%);
    }
    
    #forceDark:checked ~ #bodyContainer #icon:hover, #forceDark:checked ~ #bodyContainer #icon:focus {
        filter: brightness(80%) saturate(150%);
        outline: none;
    }
    
    #forceDark:checked ~ #bodyContainer #icon:active {
        filter: brightness(30%) saturate(30%);
    }

	.atomfeedlink, #forceLight:checked ~ #bodyContainer .atomfeedlink {
		filter: none;
	}
	.atomfeedlink:hover, .atomfeedlink:focus, #forceLight:checked ~ #bodyContainer .atomfeedlink:focus, #forceLight:checked ~ #bodyContainer .atomfeedlink:hover {
		filter: brightness(120%) saturate(120%);
		outline: none;
	}
	.atomfeedlink:active,  #forceLight:checked ~ #bodyContainer .atomfeedlink:active {
		filter: brightness(80%) saturate(80%);
	}

	#forceDark:checked ~ #bodyContainer .atomfeedlink {
        filter: brightness(50%) saturate(120%);
    }
    
    #forceDark:checked ~ #bodyContainer .atomfeedlink:hover, #forceDark:checked ~ #bodyContainer .atomfeedlink:focus {
        filter: brightness(80%) saturate(150%);
        outline: none;
    }
    
    #forceDark:checked ~ #bodyContainer .atomfeedlink:active {
        filter: brightness(30%) saturate(30%);
    }

    #forceLight:checked ~ #bodyContainer #forceLightLabel {
		border-color: black
	}

	#forceDark:checked ~ #bodyContainer #forceDarkLabel {
		border-color: white;
	}
	#useOSTheme:checked ~ #bodyContainer #useOSThemeLabel {
		border-color: black;
	}

    #forceDarkLabel, #forceDark:checked ~ #bodyContainer header .theme {
        background-color: darkblue;
        background-image: url("/static/img/moon.svg");
    }

    #forceLightLabel, #forceLight:checked ~ #bodyContainer header .theme {
        background-color: paleturquoise;
        background-image: url("/static/img/sun.svg");
    }

    header .theme {
        background-color: paleturquoise;
        background-image: url("/static/img/sun.svg");
    }

    #useOSThemeLabel {
        background-color: paleturquoise;
        background-image: url("/static/img/sun-auto.svg");
    }

    header nav a, dialog .buttons button, #icon, #forceLight:checked ~ #bodyContainer header nav a, #forceLight:checked ~ #bodyContainer dialog .buttons button, #forceLight:checked ~ #bodyContainer #icon, #forceDark:checked ~ #bodyContainer header nav a, #forceDark:checked ~ #bodyContainer dialog .buttons button, #forceDark:checked ~ #bodyContainer #icon {
        background-color: transparent;
        color: inherit;
        transition: background-color linear 0.2s;
    }

    header nav a:hover, dialog .buttons button:hover, #forceLight:checked ~ #bodyContainer header nav a:hover, #forceLight:checked ~ #bodyContainer dialog .buttons button:hover,
    header nav a:focus, dialog .buttons button:focus, #forceLight:checked ~ #bodyContainer header nav a:focus, #forceLight:checked ~ #bodyContainer dialog .buttons button:focus {
		background-color: #66a6ff;
        color: inherit;
        outline: none;
	}

    #forceDark:checked ~ #bodyContainer header nav a:hover, #forceDark:checked ~ #bodyContainer dialog .buttons button:hover,
    #forceDark:checked ~ #bodyContainer header nav a:focus, #forceDark:checked ~ #bodyContainer dialog .buttons button:focus {
		background-color: #152235;
        color: inherit;
	}

	header nav a:active, dialog .buttons button:active, #forceLight:checked ~ #bodyContainer header nav a:active, #forceLight:checked ~ #bodyContainer dialog .buttons button:active {
		background-color: blue;
        color: inherit;
	}

	#forceDark:checked ~ #bodyContainer header nav a:active, #forceDark:checked ~ #bodyContainer dialog .buttons button:active {
		background-color: rgb(4, 4, 44);
        color: inherit;
	}

    dialog .buttons button:disabled, #forceLight:checked ~ #bodyContainer dialog .buttons button:disabled {
        background-color: darkgray;
    }

    #forceDark:checked ~ #bodyContainer dialog .buttons button:disabled {
        background-color: #5f5f5f;
    }

    .authorsnote, #forceLight:checked ~ #bodyContainer .authorsnote {
        background-color: #A1F6FF;
		box-shadow: black 3px 3px 10px;
        border-color: black;
    }

    #forceDark:checked ~ #bodyContainer .authorsnote {
		background-color: #1c393d;
	}

    .tagList .unknownTag, #forceLight:checked ~ #bodyContainer .tagList .unknownTag {
        background-color: #a6a6a6;
    }
    .tagList .typeTag, #forceLight:checked ~ #bodyContainer .tagList .typeTag {
        background-color: #66ffa6;
    }
    .tagList .seriesTag, #forceLight:checked ~ #bodyContainer .tagList .seriesTag {
        background-color: #66a6ff;
    }
    .tagList .allTag, #forceLight:checked ~ #bodyContainer .tagList .allTag {
        background-color: #ffa666;
    }
    .tagList .tagTag, #forceLight:checked ~ #bodyContainer .tagList .tagTag {
        background-color: #ffff66;
    }

    #forceDark:checked ~ #bodyContainer .tagList .unknownTag {
        background-color: #3a3a3a;
    }
    #forceDark:checked ~ #bodyContainer .tagList .typeTag {
        background-color: #19442b;
    }
    #forceDark:checked ~ #bodyContainer .tagList .seriesTag {
        background-color: #18263a;
    }
    #forceDark:checked ~ #bodyContainer .tagList .allTag {
        background-color: #4d3320;
    }
    #forceDark:checked ~ #bodyContainer .tagList .tagTag {
        background-color: #505024;
    }
}

@media only screen and (prefers-color-scheme: dark) {
    #bodyContainer {
        color: white;
    }
     a,  button {
		color: #A1F6FF;
	}

    header .theme, #useOSThemeLabel {
        background-color: darkblue;
    }
    header .theme {
        background-image: url("/static/img/moon.svg");
    }
    #useOSThemeLabel {
        background-image: url("/static/img/moon-auto.svg");
    }

    a:hover,  button:hover:not(:disabled),
	 a:focus,  button:focus:not(:disabled) {
		color: dodgerblue;
        outline: none;
	}

	 a:active,  button:active:not(:disabled) {
		color: blue;
	}

     #icon {
        filter: brightness(50%) saturate(120%);
    }
    
     #icon:hover, #icon:focus {
        filter: brightness(80%) saturate(150%);
        outline: none;
    }
    
     #icon:active {
        filter: brightness(30%) saturate(30%);
    }
    .atomfeedlink {
        filter: brightness(50%) saturate(120%);
    }
    
    .atomfeedlink:hover, atomfeedlink:focus {
        filter: brightness(80%) saturate(150%);
        outline: none;
    }
    
    .atomfeedlink:active {
        filter: brightness(30%) saturate(30%);
    }

     #useOSTheme:checked ~ #bodyContainer #useOSThemeLabel {
		border-color: white;
	}

     header nav a:hover,  dialog .buttons button:hover,
     header nav a:focus,  dialog .buttons button:focus {
		background-color: #152235;
        outline: none;
	}
     header nav a:active,  dialog .buttons button:active {
		background-color: rgb(4, 4, 44);
	}
     dialog .buttons button:disabled {
        background-color: #5f5f5f;
    }
     .authorsnote {
		background-color: #1c393d;
	}
     .tagList .unknownTag {
        background-color: #3a3a3a;
    }
     .tagList .typeTag {
        background-color: #19442b;
    }
     .tagList .seriesTag {
        background-color: #18263a;
    }
     .tagList .allTag {
        background-color: #4d3320;
    }
     .tagList .tagTag {
        background-color: #505024;
    }
}