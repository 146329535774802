@media only screen { 
    #bodyContainer, #forceLight:checked ~ #bodyContainer {
        background-color: #082567;
        color-scheme: light;
    }

    header nav, #forceLight:checked ~ #bodyContainer header nav {
        background-color: #7cc8fa;
    }

    main, dialog, #forceLight:checked ~ #bodyContainer main, #forceLight:checked ~ #bodyContainer dialog {
        background-color: #c4dada;
    }

    #forceDark:checked ~ #bodyContainer {
        background-color: #020B21;
        color-scheme: dark;
    }

    #forceDark:checked ~ #bodyContainer header nav {
        background-color: #143F60;
    }

    #forceDark:checked ~ #bodyContainer main, #forceDark:checked ~ #bodyContainer dialog {
        background-color: #01184C;
    }
}
@media only screen and (prefers-color-scheme: dark) {
    #bodyContainer {
        background-color: #020B21;
        color-scheme: dark;
    }

    header nav {
        background-color: #143F60;
    }

    main, dialog {
        background-color: #01184C;
    }
}