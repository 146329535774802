body.debugVariants .variantCheck {
	background-color: rgba(0, 100, 0, 0.699);
	color: aliceblue;
}

.variantCheckIndicator {
    display: none;
}

body.debugVariants .variantCheckIndicator {
    display: inline;
	background-color: transparent;
}

body.debugVariants .variantCheck p {
	background-color: inherit;
}

body.debugVariants .variantCheckIndicator button {
	border: none;
	background-color: transparent;
}

body.debugVariants .variantCheckIndicator span {
	display: none;
}

body.debugVariants .variantCheckIndicator:focus-within span::after {
	content: " ";
}

body.debugVariants .variantCheckIndicator:focus-within span {
	display: inline;
	font-size: smaller;
}