body {
	font-family: 'Noto Sans', sans-serif;
	font-size: 16px;
}

.hiddenControl {
	position: fixed;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	border: 0;
	padding: 0;
	margin: 0;
	clip-path: inset(0.5px);
	overflow: hidden;
	appearance: none;
}

#bodyContainer {
	min-width: 100%;
	min-height: 100dvh;
	height: auto;
	margin: 0;
	padding: 0;
	overflow: visible;
}
/* Required to make the background color fill the page rather than #bodyContainer moving down with its children's margins*/
#bodyContainer::before {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background: transparent;
	margin: 0;
	margin-bottom: -1px;
}

.nsfwwarning, .draftwarning {
	display: inline-block;
	font-size: 50%;
	line-height: 1.2em;
	margin-left: 0.5em;
	height: 1.2em;
	font-weight: bold;
	border-width: 1px;
	border-style: solid;
	border-radius: .3em;
	padding: .1em .25em;
	white-space: nowrap;
	overflow: hidden;
	align-self: flex-start;
}

.nsfwwarning ~ .draftwarning {
	margin-left: 0.2em;
}

.tagList {
	display: flex;
	flex-wrap: wrap;
	margin: 0.2em 0;
	padding: 0;
	font-size: smaller;
	gap: 3px;
	max-width: 100%;
}
.tagList .tag {
	list-style: none;
	display: inline-block;
	white-space: nowrap;
	border: 1px solid black;
	border-radius: 4px;
}
.tagList .tag a, #forceLight:checked ~ #bodyContainer .tagList .tag a, #forceDark:checked ~ #bodyContainer .tagList .tag a {
	display: inline-block;
	width: 100%;
	height: 100%;
	line-height: 100%;
	padding: 5px;
	text-decoration: none;
	box-sizing: border-box;
}

.tagNav {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 0.5em;
	padding: 0;
	margin: 1em 0 0;
}

.tagNav .tag {
	display: flex;
	flex-direction: row;
	gap: 1em;
	align-items: baseline;
}

.tagNav .tag .prevPost, .tagNav .tag .nextPost{
	flex: 1 0 0;	
}
.tagNav .tag .tagName {
	flex: 0 0 max-content;
	max-width: 40%;
	text-align: center;
}
.tagNav .tag .prevPost {
	text-align: left;
}
.tagNav .tag .prevPost::before {
	content: '« ';
}
.tagNav .tag .nextPost {
	text-align: right;
}
.tagNav .tag .nextPost::after {
	content: ' »';
}

h1.postTitle {
	margin-block-start: 1.33rem;
	font-size: 2rem;
	margin-bottom: 0.5rem;
}
h3.postTitle {
	font-size: 1.2rem;
	margin-bottom: 0.2em;
}

h3.postTitle a, #forceLight:checked ~ #bodyContainer h3.postTitle a, #forceDark:checked ~ #bodyContainer h3.postTitle a {
	color: inherit;
	text-decoration: none;
}

h3.postTitle a:hover, #forceLight:checked ~ #bodyContainer h3.postTitle a:hover, #forceDark:checked ~ #bodyContainer h3.postTitle a:hover,
h3.postTitle a:focus, #forceLight:checked ~ #bodyContainer h3.postTitle a:focus, #forceDark:checked ~ #bodyContainer h3.postTitle a:focus {
	text-decoration: underline;
	outline: none;
}

p.postTime {
	margin-top: 0.2em;
	margin-bottom: 0.2em;
	font-size: 0.833em;
}

section p.postTime {
	font-size: 0.69em;
}

div.postSummary {
	margin: 0.2em 0 0 0;
	font-size: smaller;
}

header, h1, h2, h3, h4, h5, h6 {
	font-family: 'Varela Round', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.postText h1 a, .postText h2 a, .postText h3 a, .postText h4 a, .postText h5 a, .postText h6 a, #forceDark:checked ~ #bodyContainer .postText h1 a, #forceDark:checked ~ #bodyContainer .postText h2 a, #forceDark:checked ~ #bodyContainer .postText h3 a, #forceDark:checked ~ #bodyContainer .postText h4 a, #forceDark:checked ~ #bodyContainer .postText h5 a, #forceDark:checked ~ #bodyContainer .postText h6 a, #forceLight:checked ~ #bodyContainer .postText h1 a, #forceLight:checked ~ #bodyContainer .postText h2 a, #forceLight:checked ~ #bodyContainer .postText h3 a, #forceLight:checked ~ #bodyContainer .postText h4 a, #forceLight:checked ~ #bodyContainer .postText h5 a, #forceLight:checked ~ #bodyContainer .postText h6 a {
	text-decoration: inherit;
	color: inherit;
}

.postText h1 a:hover, .postText h2 a:hover, .postText h3 a:hover, .postText h4 a:hover, .postText h5 a:hover, .postText h6 a:hover,
.postText h1 a:focus, .postText h2 a:focus, .postText h3 a:focus, .postText h4 a:focus, .postText h5 a:focus, .postText h6 a:focus {
	text-decoration: underline;
	outline: none;
}

footer {
	font-size: 12px;
}

footer img.license {
	border-width: 0;
}

section article {
	margin-bottom: 2rem;
}

div.postDescription p:first-child {
	margin-top: 0;
}

div.postDescription p:last-child {
	margin-bottom: 0;
}

div.postText p:last-child {
	margin-bottom: 0;
}

div.postDescription {
	margin: 0;
}

.clear {
	clear: both;
}

img {
	max-width: 100%;
	object-fit: scale-down;
}

img.float-left, img.float-right {
	max-width: 20%;
	object-fit: scale-down;
}

img.float-left-big, img.float-right-big {
	max-width: 40%;
	object-fit: scale-down;
}

.float-left, .float-left-big {
	float: left;
	margin-right: 1em;
	margin-bottom: 1em;
}

.float-right, .float-right-big {
	float: right;
	margin-left: 1em;
	margin-bottom: 1em;
}

.ink-credit {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.ink-credit-text {
	margin-left: 0.5rem;
}

.clear-left {
	clear: left;
}

.clear-right {
	clear: right;
}

img.emoji {
	width: 1.5em;
	height: 1.5em;
}

@media only print {
	header {
		display: none;
	}

	#icon {
		display: none;
	}

	footer a {
		text-decoration: none;
		font-weight: bold;
	}

	nav {
		display: none;
	}

	.authorsnote {
		padding: 5px;
		border-width: 1px;
		border-style: solid;
	}

	dialog {
		display: none;
	}
}

@media only screen {
	body {
		margin: 0;
	}


	header .theme {
		display: flex;
		position: absolute;
		flex-flow: row nowrap;
		gap: 0;
		top: 0;
		z-index: 2;
		background-repeat: no-repeat;
		height: 32px;
		width: 32px;
		padding: 0;
		border: 0;
		border-radius: 16px;
		overflow: hidden;
		white-space: nowrap;
		line-height: 32px;
		font-family: inherit;
		font-size: 16px;
		color: transparent;
		text-decoration: none;
		text-align: center;
		transition: width ease 0.1s;

		background-origin: border-box;
		background-position: right 4px bottom 4px;
		background-size: 24px 24px;
	}

	header .theme label {
		display: block;
		background-repeat: no-repeat;
		background-origin: border-box;
		background-position: center;
		background-size: 20px 20px;
		height: 24px;
		width: 0;
		padding: 0;
		border-radius: 14px;
		overflow: hidden;
		white-space: nowrap;
		font-family: inherit;
		color: transparent;
		text-decoration: none;
		text-align: center;
		border: 0;
		transition: width ease 0.1s, border ease 0.1s;
	}

    #forceLight:checked:not(:focus) ~ #bodyContainer .theme:not(:hover) #forceLightLabel,
    #forceDark:checked:not(:focus) ~ #bodyContainer .theme:not(:hover) #forceDarkLabel,
    #useOSTheme:checked:not(:focus) ~ #bodyContainer .theme:not(:hover) #useOSThemeLabel {
        background-size: 24px 24px;
        height: 32px;
        width: 32px;
		background-image: inherit;
    }

	header .theme:hover, .hiddenControl[name="theme"]:focus ~ #bodyContainer header .theme {
		width: 88px;
		height: 28px;
		padding: 2px 40px 2px 2px;
		gap: 2px;
	}

	header .theme:hover label, .hiddenControl[name="theme"]:focus ~ #bodyContainer header .theme label {
		width: 24px;
		border: 2px solid transparent;
	} 

	#icon, main, nav, dialog {
		border-color: black;
	}

	#icon {
		display: block;
		border-width: 3px;
		border-style: solid;
		overflow: hidden;
		padding: 0;
		background-size: cover;
		background-position: center;
		background-image: url("/icon-hd.webp");
	}

	header nav {
		position: relative;
		display: block;
		box-sizing: border-box;
		overflow: hidden;
		z-index: 1;
		border-width: 0;
		border-style: solid;
	}

	header nav li a[data-current] {
        font-weight: bold;
    }

	header nav li a::before {
		content: "〈 ";
		padding-left: 1em;
	}

	header nav li a::after {
		content: " 〉";
		padding-right: 1em;
	}

	header nav li a:not([data-current])::before, header nav li a:not([data-current])::after {
		color: transparent;
	}

	header nav h1 {
		display: block;
		font-weight: bold;
		margin: 0;
		padding: 0;
		font-size: 20px;
		justify-content: center;
	}

	header nav a, header nav li {
		display: block;
		text-decoration: none;
		color: inherit;
		white-space: nowrap;
	}

	header nav ul {
		display: flex;
		list-style: none;
		padding: 0;
		margin: 0;
		flex-flow: column;
	}

	header nav li {
		flex: 1 0 0;
	}

	header nav a {
		padding: 4px;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		white-space: pre;
	}

	main {
		display: block;
	}

	main, dialog {
		padding: 10px;
		min-height: 40px;
		width: min-content;
		border-width: 0;
		border-style: solid;
		box-sizing: border-box;
	}

	dialog[open] {
		max-width: 700px;
		width: calc(100vw - 20px);
		max-height: calc(100vh - 20px);
		padding: 0.5em;
		border-width: 3px;
		border-radius: 6px;
	}

	dialog[open], dialog > form {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		align-content: stretch;
		overflow: auto;
	}

	dialog > :first-child, dialog > form:first-child > :first-child {
		margin-top: 0;
	}
	dialog > :last-child, dialog > form:last-child > :last-child {
		margin-bottom: 0;
	}

	dialog h1 {
		margin-bottom: 0.2em;
	}

	.exportLog {
		flex: 1 1 auto;
		white-space: pre-wrap;
		overflow: auto;
		margin: 1em 0.5em;
		min-height: 2em;
	}

	dialog .buttons {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		align-content: stretch;
	}

	dialog .buttons button {
		flex: 1 0 0;
		height: 2em;
		font-size: larger;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		text-decoration: none;
		border-width: 2px;
		border-style: solid;
		margin: 0.2em;
		font-family: inherit;
		box-sizing: border-box;
	}

	dialog .buttons .buttonIcon {
		flex: 0 0 auto;
		margin-left: 0.5em;
		margin-right: 0.1em;
		text-align: center;
	}

	dialog .buttons .buttonText {
		flex: 1 1 auto;
		margin-left: 0.1em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}

	footer {
		display: block;
		padding: 13px;
		box-sizing: border-box;
	}

	footer a {
		text-decoration: none;
		font-weight: bold;
	}

	#license-image {
		float: left;
		margin: 2px 10px 2px 0;
	}

	#credits {
		clear: left;
	}

	.atomfeedlink {
		height: 1em;
		width: 0;
		background-image: url("/static/img/feed-icon.svg");
		background-size: cover;
		overflow: hidden;
		padding-left: 1em;
		margin-left: auto;
		color: transparent;
	}

	.authorsnote {
		padding: 0.5em;
		border-width: 1px;
		border-style: solid;
	}

	.authorsnote > :first-child {
		margin-top: 0;
	}

	.authorsnote > :last-child {
		margin-bottom: 0;
	}
}

@media only screen and (pointer: coarse) {
	header nav a {
		height: 48px;
	}

	header .theme {
		gap: 0;
		height: 64px;
		width: 64px;
		border-radius: 64px;
		background-position: right 8px bottom 8px;
		background-size: 48px 48px;
	}

	header .theme label {
		background-size: 40px 40px;
		height: 48px;
		width: 0;
		border-radius: 28px;
		padding: 0;
		border: 0;
	}

	header .theme:hover, .hiddenControl[name="theme"]:focus ~ #bodyContainer header .theme {
		width: 176px;
		height: 56px;
		padding: 4px 80px 4px 4px;
		gap: 4px;
	}

	header .theme:hover label, .hiddenControl[name="theme"]:focus ~ #bodyContainer header .theme label {
		width: 48px;
		border: 4px solid transparent;
	} 

    #forceLight:checked:not(:focus) ~ #bodyContainer .theme:not(:hover) #forceLightLabel,
    #forceDark:checked:not(:focus) ~ #bodyContainer .theme:not(:hover) #forceDarkLabel,
    #useOSTheme:checked:not(:focus) ~ #bodyContainer .theme:not(:hover) #useOSThemeLabel  {
        background-size: 48px 48px;
        height: 64px;
        width: 64px;
    }
}