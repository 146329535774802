html:not([data-persistence=on]) .persistenceOnly,
html[data-persistence=on] .noPersistence,
html:not([data-persistence=jsOnly]) .noCookies,
html[data-persistence] .noJavascript,
.preferenceItem:not(.preferenceSet) .preferenceCurrentStoredValue,
.preferenceItem:not(.preferenceSet) .preferenceClear,
.preferenceItem.preferenceSet
.preferenceUnset,
html:not([data-persistence=on]) .preferenceUnset,
html:not([data-persistence-display=active]) .persistenceDisplayOnly,
html:not([data-persistence-display=loading]) .persistenceDisplayLoading,
html[data-persistence-display] .persistenceDisplayFailure {
    display: none;
}

.preferenceStoredKey, .preferenceStoredValue, .preferenceCurrentStoredValue {
    font-family: 'Fira Code', monospace;
    padding: 0 0.2em;
}

.preferenceCurrentStoredValue {
    font-weight: bold;
}

.preferenceClear {
    margin-left: 0.5em;
}

.preferenceItem .preferenceItemDescription {
    font-weight: bold;
    font-size: larger;
}

.preferenceValueItem.preferenceSet .preferenceValueDescription {
    font-weight: bold;
}

.preferenceUnset {
    font-style: italic;
}

.preferenceValueItem.preferenceSet::after {
    content: " - saved";
    font-style: italic;
}
