[lang=ja] {
	font-family: 'Noto Sans JP', sans-serif;
}

h1 [lang=ja], h2 [lang=ja], h3 [lang=ja], h4 [lang=ja], h5 [lang=ja], h6 [lang=ja] {
	font-family: 'Kosugi Maru', sans-serif;
}

.permanentMarker {
  font-family: 'Permanent Marker', cursive;
  font-weight: bold;
}

.glitch-text {
  font-family: 'Fira Code', monospace;
  font-weight: bold;
}

.embiggen {
  font-size: 1.5em;
}

.turbo-embiggen {
  font-size: 2em;
}

.fakeTextbox {
  border: 1px solid currentColor;
  border-radius: 3px;
  color: #222222;
  padding: 4px;
  margin: 0.3em;
  background-color: #DDDDDDCC;
  font-family: 'Karla', sans-serif;
}

#forceDark:checked ~ #bodyContainer .fakeTextbox {
  color: #DDDDDD;
  background-color: #222222;
}

@keyframes blinkcursor {
  50% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.fakeTextbox .insertionPoint {
  content: "\200C";
  border-left: 1px solid currentColor;
  font-weight: 100;
  animation: 700ms step-end 0s infinite alternate both running blinkcursor;
  letter-spacing: 0;
}

blockquote.chatbubbles {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  border-radius: 10px / 30px;
  border-width: 30px 10px;
  border-style: solid;
  font-size: 0.95em;
}

@media screen {
  blockquote.chatbubbles, #forceLight:checked ~ #bodyContainer blockquote.chatbubbles {
    border-color: #222222;
    background-color: #dfbbf7;
  }
  #forceDark:checked ~ #bodyContainer blockquote.chatbubbles {
    background-color: #1c061d;
    border-color: #2c2c2c;
  } 
  blockquote.chatbubbles div.header, #forceLight:checked ~ #bodyContainer blockquote.chatbubbles div.header {
    background-color: #ad75db;
    border-bottom-color: #532975;
  }
  #forceDark:checked ~ #bodyContainer blockquote.chatbubbles div.header {
    background-color: hsl(323, 100%, 15%);
    border-bottom-color: hsl(323, 65%, 28%);
  }

  blockquote.chatbubbles p, #forceLight:checked ~ #bodyContainer blockquote.chatbubbles p {
    color: #222222;
  }
  #forceDark:checked ~ #bodyContainer blockquote.chatbubbles p {
    color: #DDDDDD;
  }
  blockquote.chatbubbles div.chatbubble, #forceLight:checked ~ #bodyContainer blockquote.chatbubbles div.chatbubble {
    color: #b483e2;
  }
  #forceDark:checked ~ #bodyContainer blockquote.chatbubbles div.chatbubble {
    color: #361653;
  } 
  blockquote.chatbubbles div.chatbubble.self, #forceLight:checked ~ #bodyContainer blockquote.chatbubbles div.chatbubble.self {
    color: #53daaf;
  }
  #forceDark:checked ~ #bodyContainer blockquote.chatbubbles div.chatbubble.self {
    color: #094431;
  }
}

@media only screen and (prefers-color-scheme: dark) {
  blockquote.chatbubbles {
    background-color: #1c061d;
    border-color: #2c2c2c;
  } 
  blockquote.chatbubbles div.header {
    background-color: hsl(323, 100%, 15%);
    border-bottom-color: hsl(323, 65%, 28%);
  }
  blockquote.chatbubbles p {
    color: #DDDDDD;
  }
  blockquote.chatbubbles div.chatbubble {
    color: #361653;
  } 
  blockquote.chatbubbles div.chatbubble.self {
    color: #094431;
  }
}

blockquote.chatbubbles div.header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 0.2em 0.5em;
}
blockquote.chatbubbles div.header p.chatname {
  font-size: 1.5em;
  font-family: 'Braah One', sans-serif;
  font-weight: bold;
  margin: 0.2em 0 -0.2em;
}
blockquote.chatbubbles p.chatauthor {
  font-family: 'Braah One', sans-serif;
  font-weight: bold;
  margin: 0.5em 0 -0.2em;
}
blockquote.chatbubbles p.datestamp {
  font-family: 'Karla', sans-serif;
  font-weight: bold;
  text-align: center;
  opacity: 70%;
  margin: 0.5em 0 0.2em;
}
blockquote.chatbubbles div.chatbubble p {
  font-family: 'Karla', sans-serif;
}
blockquote.chatbubbles div.header p.subtitle {
  font-family: 'Karla', sans-serif;
  margin: 0.2em 0;
}
blockquote.chatbubbles div.chatbubble {
  position: relative;
	background-color: currentColor;
	border-radius: 5px;
  width: fit-content;
  min-height: 20px;
  padding: 0.5em 0.5em;
  margin: 0.2em 0;
}
blockquote.chatbubbles div.chatbubble.loading {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* background-image: url("/static/img/fake-loading.svg"); */
}

@keyframes pulseDots {
  from {
    opacity: 0.2;
  }
  10% {
    opacity: 0.2;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
blockquote.chatbubbles div.fakeTextbox {
  margin: 0.2em 5%;
}
blockquote.chatbubbles div.chatbubble.typing p {
  font-size: 0.8em;
  animation: pulseDots 1000ms linear 0s infinite alternate-reverse both;
}
blockquote.chatbubbles div.chatbubble.emojionly p {
  font-size: 2em;
}
blockquote.chatbubbles div.chatbubble p {
  z-index: 1;
}
blockquote.chatbubbles div.chatbubble p:first-child, blockquote.chatbubbles div.fakeTextbox p:first-child {
  margin-top: 0;
}
blockquote.chatbubbles div.chatbubble p:last-child, blockquote.chatbubbles div.fakeTextbox p:last-child {
  margin-bottom: 0;
}
blockquote.chatbubbles div.system {
  text-align: center;
  font-family: 'Karla', sans-serif;
  margin: 0.5em 0.2em;
}
blockquote.chatbubbles p.chatauthor {
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
  font-size: smaller;
  font-weight: bold;
  font-family: 'Braah One', sans-serif;
}
blockquote.chatbubbles p.chatauthor.self {
  text-align: right;
}
blockquote.chatbubbles p.chatauthor.other {
  text-align: left;
}
blockquote.chatbubbles div.chatbubble.self {
  margin-right: 5%;
  margin-left: auto;
  max-width: 80%;
}
blockquote.chatbubbles div.chatbubble.other {
  margin-left: 5%;
  margin-right: auto;
  max-width: 80%;
}
blockquote.chatbubbles div.chatbubble.self::after, blockquote.chatbubbles div.chatbubble.other::after {
	content: '';
	position: absolute;
	bottom: 5px;
	width: 0;
	height: 0;
	border: 5px solid transparent;
  z-index: 0;
}

blockquote.chatbubbles div.chatbubble.other::after {
  left: 0;
	border-right-color: currentColor;
	border-left: 0;
	margin-left: -4px;
}

blockquote.chatbubbles div.chatbubble.self::after {
  right: 0;
	border-left-color: currentColor;
	border-right: 0;
	margin-right: -4px;
}

.fade-1 {
  opacity: 0.75;
}
.fade-2 {
  opacity: 0.6;
}
.fade-3 {
  opacity: 0.4;
}
.fade-4 {
  opacity: 0.2;
}
.fade-5 {
  opacity: 0.15;
}
.fade-6 {
  opacity: 0.06;
}
.fade-7 {
  opacity: 0.04;
}