#compiledInk, #inkSource {
    display: none;
    white-space: break-spaces;
}

#interactiveBody p:last-child {
	margin-bottom: 0;
}

#interactiveBody ul.choices:not(.active) li.chosen {
    font-weight: bold;
}

#interactiveBody ul.choices:not(.active) li:not(.chosen) {
    list-style-type: none;
}

#interactiveBody p.finale {
    margin-left: 2em;
    font-weight: bold;
}

#interactiveBody p.finale::before {
    content: "🙗 ";
}

@keyframes fadeIn {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

span.fadeIn {
    animation: 250ms ease-in both fadeIn;
}